import { make } from 'vuex-pathify';

const online = {
  namespaced: true,
  state: {
    users: [],
  },
  mutations: {},
  actions: {},
};

const mutations = {};

const actions = {};

online.mutations = Object.assign(make.mutations(online.state), mutations);
online.actions = Object.assign(online.actions, actions);

online.namespaced = true;

export default online;
