/* eslint-disable global-require */
/* eslint-disable no-alert */

import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth';

import router from './router';

axios.defaults.baseURL = Vue.prototype.$api;

axios.interceptors.response.use(
  response => response,
  error => {
    // console.log('axios.error', error.response.data);
    if (error.response.data === 'maintenance-mode') {
      alert('Coworking Bansko Exchange will be back shortly');
    }
    return Promise.reject(error);
  },
);

window.axios = axios; // important for vue-tables-2

Vue.router = router; // important for vue-auth

Vue.use(VueAxios, axios);
Vue.use(VueAuth, {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),

  authRedirect: { path: '/' },

  loginData: {
    url: '/api/v1/auth/auto',
    method: 'POST',
    redirect: false,
    fetchUser: false,
  },

  logoutData: {
    url: '/api/v1/auth/logout',
    method: 'GET',
    redirect: false,
    makeRequest: true,
  },

  refreshData: {
    url: '/api/v1/auth/refresh',
    method: 'GET',
    enabled: false,
    interval: 30,
    error() {
      window.localStorage.clear();
      window.location = '/';
    },
  },

  fetchData: {
    url: '/api/v1/auth/user',
    method: 'GET',
    enabled: true,
  },

});
