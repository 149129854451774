import Bowser from 'bowser';

export default {

  data() {
    return {
      requirements: {
        reason: '',
      }
    }
  },

  methods: {
    meetsRequirements() {
      const bowser = Bowser.parse(window.navigator.userAgent);

      if(bowser.os.name === 'iOS' && parseFloat(bowser.os.version) < 13.6) {
        this.requirements.reason = `Sorry... <br><br>This service requires iOS 13.6 or greater and you are on iOS ${bowser.os.version}.<br><br>Please update the software update on your phone.`;
        return false;
      }

      return true;
    },
  },
};
