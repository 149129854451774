import Vue from 'vue';
import Echo from 'laravel-echo';

let pusherConfig;

if (process.env.NODE_ENV === 'development') {
  pusherConfig = {
    broadcaster: 'pusher',
    key: 'drrNisaxKSrn', // laravel-websockets
    wsHost: 'socketmaster.test',
    wssPort: 6001,
    enableStats: false,
    enabledTransports: ['ws', 'wss'],
  };
} else {
  pusherConfig = {
    broadcaster: 'pusher',
    key: 'drrNisaxKSrn', // laravel-websockets
    wsHost: 'socket.cbass.dev',
    wssPort: 443,
    enableStats: false,
    enabledTransports: ['ws', 'wss'],
  };
}

window.Pusher = require('pusher-js');

window.Echo = new Echo(pusherConfig);

window.Echo.connector.pusher.config.authEndpoint = `${Vue.prototype.$api}/broadcasting/auth`;

// console.log(`${Vue.prototype.$api}/broadcasting/auth`);
