import { make } from 'vuex-pathify';

const site = {
  namespaced: true,
  state: {
    locked: true,
    activeFont: 'Gorditas',
    activeTheme: 'vanilla',
  },
  mutations: {},
  actions: {},
};

const mutations = {};

const actions = {};

site.mutations = Object.assign(make.mutations(site.state), mutations);
site.actions = Object.assign(site.actions, actions);

site.namespaced = true;

export default site;
