/* eslint-disable default-case */
/* eslint func-names: ["error", "never"] */

import Bowser from 'bowser';
import { sync } from 'vuex-pathify';

export default {

  computed: {
    ...sync([
      'device/isMobile', //
      'device/device', //
      'device/platform', //
      'device/windowHeight', //
      'device/windowWidth', //
    ]),
  },

  watch: {
    windowWidth() {
      this.isMobile = this.$is_app || this.$device.mobile || this.windowWidth < 768;
    },
  },

  methods: {
    detectDevice() {
      this.device = {
        bowser: Bowser.parse(window.navigator.userAgent),
        device: this.$device,
        userAgent: window.navigator.userAgent,
      };
    },

    detectPlatform() {
      this.platform = process.env.CORDOVA_PLATFORM || 'web';
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },

    trackOrientation() {
      $(window).bind('orientationchange', () => {
        switch (window.orientation) {
          case 0:
          case 180:
          // The device is in portrait mode now
            console.log('portrait');
            // $('.turnDeviceNotification').css('display', 'none');
            break;

          case 90:
          case -90:
          // The device is in landscape now
            console.log('landscape');
            // $('.turnDeviceNotification').css('display', 'block');
            break;
        }
      });
    },

    clearSiteData() {
      console.log('flushing cache...');
      localStorage.clear();
      window.location.reload(true);
    },
  },

  mounted() {
    Bus.$on('site-data:clear', () => {
      this.clearSiteData();
    });

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    this.onResize();

    this.detectDevice();

    this.detectPlatform();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    Bus.$off('site-data:clear');
  },
};
