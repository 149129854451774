import { make } from 'vuex-pathify';

const meta = {
  namespaced: true,
  state: {
    title: process.env.VUE_APP_SITE_TITLE,
    description: process.env.VUE_APP_SITE_TITLE,
    url: process.env.VUE_APP_SITE_URL,
    canonical: process.env.VUE_APP_SITE_URL,
  },
  mutations: {},
  actions: {},
};

meta.mutations = make.mutations(meta.state);

export default meta;
