import { make } from 'vuex-pathify';

const api = {
  namespaced: true,
  state: {
    params: {},
  },
  mutations: {},
  actions: {},
};

api.mutations = make.mutations(api.state);

export default api;
