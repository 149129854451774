import Vue from 'vue';

switch (true) {
  case window.location.hostname.includes('.test'):
    Vue.prototype.$api = process.env.VUE_APP_API_URL_DEV;
    break;

  case (process.env.NODE_ENV === 'development'):
    Vue.prototype.$api = '';
    break;

  default:
    Vue.prototype.$api = process.env.VUE_APP_API_URL_PROD;
}

Vue.prototype.$config = {
  defaultPlaylist: process.env.VUE_APP_DEFAULT_PLAYLIST,
};
