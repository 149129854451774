<template>
  <div id="app">
    <Update />

    <div v-if="locked && !isDev() && isSubDomain('webamp') && hash === ''">
      <UnderConstruction
        :title="'Wat\'s da passcode?'"
        :back-button="'back'"
        :show-button="'show'"
        :hide-button="'hide'"
        :show-loader="true"
        :total-digits="4"
        :redirect-url="'/'"
      />
    </div>

    <div v-else>
      <vue-headful
        :title="title"
        :description="description"
      />

      <div
        v-if="$route.name !== 'device' && !meetsRequirements()"
        class="tw-fixed tw-inset-0 tw-z-30 tw-flex tw-items-center tw-w-full tw-bg-white"
      >
        <div class="tw-max-w-lg tw-m-auto tw-text-center">
          <div
            class="tw-mx-2 tw-mb-4 tw-text-xl tw-font-semibold tw-leading-tight"
            v-html="requirements.reason"
          />
        </div>
      </div>
      <div v-else>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

import utilities from '@/mixins/utilities';
import requirements from '@/mixins/requirements';

export default {

  components: {
    Update: () => import('@/components/Update'),
    UnderConstruction: () => import('@/components/UnderConstruction'),
  },
  mixins: [
    utilities, //
    requirements, //
  ],

  computed: {
    ...sync([
      'site.locked', //
      'meta.title',
      'meta.description',
    ]),

    hash() {
      return window.location.hash;
    },
  },

  mounted() {
    // console.log('locked', this.locked);
    // console.log(this.$route.hash);
  },
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #000;
  font-family: arial;
  font-size: 15px;
  overflow: hidden;
  line-height: 1 !important;
}

#winamp-container {
  height: 100vh;
}

.multiselect input {
  color: #fff !important;
}

.multiselect {
  opacity: 0.7;
  margin: 1px;
}

.multiselect__spinner {
  background: #000;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
}
.multiselect {
  color: #35495e;
}
.multiselect--disabled {
  background: #ededed;
  opacity: 0.6;
}
.multiselect__input,
.multiselect__single {
  background: #000;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
}
.multiselect__tags {
  border: 1px solid #e8e8e8;
  background: #000;
  font-size: 14px;
}
.multiselect__tag {
  color: #000;
  background: #41b883;
}
.multiselect__tag-icon {
  font-weight: 700;
  font-style: normal;
}
.multiselect__tag-icon:after {
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #000;
}
.multiselect__current {
  border: 1px solid #e8e8e8;
}
.multiselect__select:before {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
}
.multiselect__placeholder {
  color: #adadad;
}
.multiselect__content-wrapper {
  background: #000;
  border: 1px solid #e8e8e8;
}
.multiselect__option:after {
  font-size: 13px;
}
.multiselect__option--highlight {
  background: #41b883;
  color: #000;
}
.multiselect__option--highlight:after {
  background: #41b883;
  color: #000;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #000;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  color: #000;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #000;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #000;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  color: #000;
}
</style>
