/* eslint-disable no-continue */

import Vue from 'vue';

/* Global Methods */
Vue.mixin({
  methods: {
    ucfirst: (str) => str.charAt(0).toUpperCase() + str.slice(1),

    slugify(value) {
      let slug = value.replace(/^\s+|\s+$/g, ''); // trim
      slug = slug.toLowerCase();

      // remove accents, swap ñ for n, etc
      const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
      const to = 'aaaaaeeeeeiiiiooooouuuunc------';
      for (let i = 0, l = from.length; i < l; i += 1) {
        slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      slug = slug
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

      return slug;
    },

    isDev() {
      return process.env.NODE_ENV === 'development';
    },

    isDisco() {
      return this.isSubDomain('disco') || this.$route.name === 'disco' || this.$route.name === 'disco-room';
    },

    isDJ() {
      return this.$route.name === 'dj';
    },

    isSubDomain(match) {
      const subdomain = window.location.host.split('.')[0];
      // console.log(`${subdomain} === ${match} ?`);
      return subdomain === match;
    },
  },
});
