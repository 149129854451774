import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import createPersistedState from 'vuex-persistedstate';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    pathify.plugin,
    // VuexORM.install(database),
    createPersistedState({
      paths: [
        /* modules */
        'site',
        'webamp',
      ],
    }),
  ],

  modules,
});

export default store;
