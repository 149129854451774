/* eslint-disable import/prefer-default-export */

import Vue from 'vue';

import 'bootstrap';
import BootstrapVue from 'bootstrap-vue';

import IdleVue from 'idle-vue';
import device from 'vue-device-detector';

import './config';
import App from './App.vue';
import router from './router';
import store from './vuex/store';
import './auth';
import './registerServiceWorker';

import '@/plugins/websockets';

import '@/global/components';
// import '@/global/components-utility';
import '@/global/mixins';

import '@/assets/css/tailwind.css';

window.Bus = new Vue();

Vue.use(BootstrapVue);

// automatic hide on idle
Vue.use(IdleVue, {
  eventEmitter: window.Bus,
  idleTime: 30000,
});

Vue.use(device);

Vue.component('vue-headful', () => import('vue-headful'));

Vue.config.productionTip = false;

export const app = new Vue({
  el: '#app',
  router,
  store,

  onIdle() {
    // console.log('isIdle');
    if (!this.$device.mobile) {
      Bus.$emit('idle:on');
    }
  },

  onActive() {
    // console.log('isActive')
    if (!this.$device.mobile) {
      Bus.$emit('idle:off');
    }
  },

  computed: {
    isAuthenticated() {
      return this.$auth.check();
    },
  },

  watch: {
    isAuthenticated() {
      window.Echo.connector.options.auth.headers.Authorization = `Bearer ${this.$auth.token()}`;
    },
  },

  render: (h) => h(App),
});
