import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const subdomain = window.location.host.split('.')[0];

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: subdomain === 'webamp' ? 'webamp' : 'disco',
      component: () => (subdomain === 'webamp' ? import('./pages/Page-Webamp') : import('./pages/Page-Disco')),
    },
    {
      path: '/webamp',
      name: 'webamp',
      component: () => import('./pages/Page-Webamp'),
    },
    {
      path: '/device',
      name: 'device',
      component: () => import('./pages/Page-DeviceInfo'),
    },
    {
      path: '/dj',
      name: 'dj',
      component: () => import('./pages/Page-Disco'),
    },
    {
      path: '/disco',
      name: 'disco',
      component: () => import('./pages/Page-Disco'),
    },
    {
      path: '/disco/:room',
      name: 'disco-room',
      component: () => import('./pages/Page-Disco'),
    },
    {
      path: '/:room',
      name: 'disco-room',
      component: () => import('./pages/Page-Disco'),
    },
  ],
});
