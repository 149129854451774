import { make } from 'vuex-pathify';

const site = {
  namespaced: true,
  state: {
    song: {},
    songs: [],
    playlist: '',
    artist: '',
    album: '',
    medium: '',
    genre: '',
    skin: {
      name: 'Zlomp',
      url: 'https://s3.amazonaws.com/webamp-uploaded-skins/skins/603adeadb11c156681179743c487391a.wsz',
    },
    options: {
      startInDesktopMode: true,
    },
  },
  mutations: {},
  actions: {},
};

const mutations = {};

const actions = {};

site.mutations = Object.assign(make.mutations(site.state), mutations);
site.actions = Object.assign(site.actions, actions);

site.namespaced = true;

export default site;
