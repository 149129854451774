import { make } from 'vuex-pathify';

const layout = {
  namespaced: true,
  state: {
    tableStack: [],
    tabIndex: 0,
    tabCount: 0,
  },
  mutations: {},
  actions: {},
};

layout.mutations = make.mutations(layout.state);

export default layout;
